/* eslint-disable */


// Programatically generated on Wed Apr 09 2025 19:08:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_guide_levels_v1',
    'attributes': {
      'level': {
        'type': 'ltree',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'reorder_question_sets': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': true
      },
      'summary': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'position': {
        'type': 'integer',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'free': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'theme_img': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'published': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'parent_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'nlevel': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'url_slug': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'tags_v1': {
        'type': 'one-to-many'
      },
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'authoring_guide_v1': {
        'type': 'one-to-one'
      },
      'authoring_parent_v1': {
        'type': 'one-to-one'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'parent_v1': {
        'type': 'one-to-one'
      },
      'parent_v2': {
        'type': 'one-to-one'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'guide_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_question_sets': Empty,
    'authoring_subject': Empty,
    'level': Empty,
    'authoring_questions': Empty,
    'reorder_question_sets': Empty,
    'summary': Empty,
    'position': Empty,
    'parent': Empty,
    'question_sets': Empty,
    'name': Empty,
    'subject': Empty,
    'free': Empty,
    'theme_img': Empty,
    'questions': Empty,
    'published': Empty,
    'search_tags': Empty,
    'parent_id': Empty,
    'authoring_parent': Empty,
    'authoring_guide': Empty,
    'search_questions': Empty,
    'nlevel': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'tags': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'url_slug': Empty,
    'guide': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'count_of_rejected_questions': Empty,
    'count_of_published_questions': Empty,
    'count_of_archived_questions': Empty,
    'count_of_visible_questions': Empty,
    'count_of_pending_questions': Empty,
    'count_of_unpublished_children_questions': Empty,
    'count_of_hidden_questions': Empty,
    'duplicate_aligned_question_set_ids': Empty,
    'questions_in_guide': Empty,
    'count_of_draft_questions': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'level': '',
    'summary': '',
    'name': '',
    'theme_img': '',
    'parent_id': '',
    'id': '',
    'url_slug': '',
    'description': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_question_sets': new List(),
    'authoring_questions': new List(),
    'question_sets': new List(),
    'questions': new List(),
    'search_tags': new List(),
    'search_questions': new List(),
    'public_questions': new List(),
    'tags': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'reorder_question_sets': new Map(),
    'position': 0,
    'parent': new Map(),
    'subject': new Map(),
    'free': false,
    'published': false,
    'authoring_parent': new Map(),
    'authoring_guide': new Map(),
    'nlevel': 0,
    'guide': new Map(),
    'relationshipMeta': new Map()
  }
);

export class AuthoringGuideLevelModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringGuideLevelModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringGuideLevelModelV1Model = _emptyAuthoringGuideLevelModelV1Model ? _emptyAuthoringGuideLevelModelV1Model : new AuthoringGuideLevelModelV1(DefaultModel);
    return _emptyAuthoringGuideLevelModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringGuideLevelModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_guide_level_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {boolean}
   */
  isFree() {
    const value = this.getValueForAttribute('free');
    return value;
  }

  setFree(value) {
    return this.setField('free', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {ltree}
   */
  getLevel() {
    const value = this.getValueForAttribute('level');
    return value;
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {integer}
   */
  getNlevel() {
    const value = this.getValueForAttribute('nlevel');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setNlevel(value) {
    return this.setField('nlevel', value);
  }

  /**
   * @type {binary_id}
   */
  getParentId() {
    const value = this.getValueForAttribute('parent_id');
    return value;
  }

  setParentId(value) {
    return this.setField('parent_id', value);
  }

  /**
   * @type {integer}
   */
  getPosition() {
    const value = this.getValueForAttribute('position');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setPosition(value) {
    return this.setField('position', value);
  }

  /**
   * @type {boolean}
   */
  isPublished() {
    const value = this.getValueForAttribute('published');
    return value;
  }

  setPublished(value) {
    return this.setField('published', value);
  }

  /**
  * @type {map}
  */
  setReorderQuestionSets(value) {
    return this.setField('reorder_question_sets', value);
  }

  /**
   * @type {string}
   */
  getSummary() {
    const value = this.getValueForAttribute('summary');
    return value;
  }

  setSummary(value) {
    return this.setField('summary', value);
  }

  /**
   * @type {string}
   */
  getThemeImg() {
    const value = this.getValueForAttribute('theme_img');
    return value;
  }

  setThemeImg(value) {
    return this.setField('theme_img', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUrlSlug() {
    const value = this.getValueForAttribute('url_slug');
    return value;
  }

  setUrlSlug(value) {
    return this.setField('url_slug', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getCountOfArchivedQuestions() {
        return this.getMeta().getCountOfArchivedQuestions();
      }
    
      getCountOfDraftQuestions() {
        return this.getMeta().getCountOfDraftQuestions();
      }
    
      getCountOfHiddenQuestions() {
        return this.getMeta().getCountOfHiddenQuestions();
      }
    
      getCountOfPendingQuestions() {
        return this.getMeta().getCountOfPendingQuestions();
      }
    
      getCountOfPublishedQuestions() {
        return this.getMeta().getCountOfPublishedQuestions();
      }
    
      getCountOfRejectedQuestions() {
        return this.getMeta().getCountOfRejectedQuestions();
      }
    
      getCountOfUnpublishedChildrenQuestions() {
        return this.getMeta().getCountOfUnpublishedChildrenQuestions();
      }
    
      getCountOfVisibleQuestions() {
        return this.getMeta().getCountOfVisibleQuestions();
      }
    
      getDuplicateAlignedQuestionSetIds() {
        return this.getMeta().getDuplicateAlignedQuestionSetIds();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getQuestionsInGuide() {
        return this.getMeta().getQuestionsInGuide();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getCountOfArchivedQuestions() {
            const value = meta.get('count_of_archived_questions');
            validate(value, 'count_of_archived_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDraftQuestions() {
            const value = meta.get('count_of_draft_questions');
            validate(value, 'count_of_draft_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfHiddenQuestions() {
            const value = meta.get('count_of_hidden_questions');
            validate(value, 'count_of_hidden_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPendingQuestions() {
            const value = meta.get('count_of_pending_questions');
            validate(value, 'count_of_pending_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPublishedQuestions() {
            const value = meta.get('count_of_published_questions');
            validate(value, 'count_of_published_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfRejectedQuestions() {
            const value = meta.get('count_of_rejected_questions');
            validate(value, 'count_of_rejected_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfUnpublishedChildrenQuestions() {
            const value = meta.get('count_of_unpublished_children_questions');
            validate(value, 'count_of_unpublished_children_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfVisibleQuestions() {
            const value = meta.get('count_of_visible_questions');
            validate(value, 'count_of_visible_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {binary_id[]}
           */
          getDuplicateAlignedQuestionSetIds() {
            const value = meta.get('duplicate_aligned_question_set_ids');
            validate(value, 'duplicate_aligned_question_set_ids');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getQuestionsInGuide() {
            const value = meta.get('questions_in_guide');
            validate(value, 'questions_in_guide');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {guide_level_v2 (one)}
   */
  getParent() {
    const value = this.getValueForAttribute('parent');
    return value;
  }

  // -- Relationship Fields: parent --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionRelationshipGetters(rel));
    } else {
      return this.__withQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_level_v1 (one)}
   */
  getAuthoringParent() {
    const value = this.getValueForAttribute('authoring_parent');
    return value;
  }

  // -- Relationship Fields: authoring_parent --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_v1 (one)}
   */
  getAuthoringGuide() {
    const value = this.getValueForAttribute('authoring_guide');
    return value;
  }

  // -- Relationship Fields: authoring_guide --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSearchQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSearchQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'search_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSearchQuestionRelationshipGetters(rel));
    } else {
      return this.__withSearchQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withPublicQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getPublicQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'public_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withPublicQuestionRelationshipGetters(rel));
    } else {
      return this.__withPublicQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_v1 (one)}
   */
  getGuide() {
    const value = this.getValueForAttribute('guide');
    return value;
  }

  // -- Relationship Fields: guide --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringGuideLevelModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'description': null,
  'free': null,
  'id': null,
  'inserted_at': null,
  'level': null,
  'meta.count_of_archived_questions': null,
  'meta.count_of_draft_questions': null,
  'meta.count_of_hidden_questions': null,
  'meta.count_of_pending_questions': null,
  'meta.count_of_published_questions': null,
  'meta.count_of_rejected_questions': null,
  'meta.count_of_unpublished_children_questions': null,
  'meta.count_of_visible_questions': null,
  'meta.questions_in_guide': null,
  'name': null,
  'nlevel': null,
  'parent_id': null,
  'position': null,
  'published': null,
  'summary': null,
  'theme_img': null,
  'updated_at': null,
  'url_slug': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_guide_v1': new Set(['associated']),
  'authoring_parent_v1': new Set(['associated']),
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'free': new Set(['exact','not','null']),
  'guide_v1': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'level': new Set(['exact','not','null']),
  'meta.count_of_archived_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_draft_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_hidden_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_pending_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_published_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_rejected_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_unpublished_children_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_visible_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.questions_in_guide': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'nlevel': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'parent_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'parent_v1': new Set(['associated']),
  'parent_v2': new Set(['associated']),
  'position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'public_questions_v1': new Set(['associated']),
  'published': new Set(['exact','not','null']),
  'question_sets_v1': new Set(['associated']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'reorder_question_sets': new Set(['null']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'summary': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'tags_v1': new Set(['associated']),
  'theme_img': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'url_slug': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'duplicate_aligned': 'meta.context.guide_level.duplicate_aligned',
    'question_status_counts': 'meta.context.guide_level.question_status_counts',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringGuideLevel = AuthoringGuideLevelModelV1;
