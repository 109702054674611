/* eslint-disable */


// Programatically generated on Wed Apr 09 2025 19:08:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'standard_sets_v1',
    'attributes': {
      'case_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'uri': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'title': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status_start_date': {
        'type': 'date',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'adoption_status': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'official_source_url': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status_end_date': {
        'type': 'date',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_subjects_v1': {
        'type': 'one-to-many'
      },
      'curriculum_areas_v1': {
        'type': 'one-to-many'
      },
      'standard_set_creator_v1': {
        'type': 'one-to-one'
      },
      'standards_v1': {
        'type': 'one-to-many'
      },
      'subjects_v1': {
        'type': 'one-to-many'
      },
      'subjects_v2': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'curriculum_areas': Empty,
    'subjects': Empty,
    'case_id': Empty,
    'uri': Empty,
    'standard_set_creator': Empty,
    'authoring_subjects': Empty,
    'standards': Empty,
    'updated_at': Empty,
    'title': Empty,
    'status_start_date': Empty,
    'adoption_status': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'official_source_url': Empty,
    'status_end_date': Empty,
    'meta': new Map({
    'content_discovery_question_count': Empty,
    'included_in_personalization_settings': Empty,
    'matching_standards_search_count': Empty,
    'populated_metadata_fields': Empty,
    'question_count': Empty,
    'search_relevance': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'case_id': '',
    'uri': '',
    'title': '',
    'adoption_status': '',
    'id': '',
    'description': '',
    'official_source_url': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'curriculum_areas': new List(),
    'subjects': new List(),
    'authoring_subjects': new List(),
    'standards': new List(),
    'meta': new Map(),
    'standard_set_creator': new Map(),
    'status_start_date': new Map(),
    'relationshipMeta': new Map(),
    'status_end_date': new Map()
  }
);

export class StandardSetModelV1 extends GenericModel(ModelRecord, ModelConfig, 'StandardSetModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyStandardSetModelV1Model = _emptyStandardSetModelV1Model ? _emptyStandardSetModelV1Model : new StandardSetModelV1(DefaultModel);
    return _emptyStandardSetModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'StandardSetModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'standard_set_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getAdoptionStatus() {
    const value = this.getValueForAttribute('adoption_status');
    return value;
  }

  setAdoptionStatus(value) {
    return this.setField('adoption_status', value);
  }

  /**
   * @type {binary_id}
   */
  getCaseId() {
    const value = this.getValueForAttribute('case_id');
    return value;
  }

  setCaseId(value) {
    return this.setField('case_id', value);
  }

  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getOfficialSourceUrl() {
    const value = this.getValueForAttribute('official_source_url');
    return value;
  }

  setOfficialSourceUrl(value) {
    return this.setField('official_source_url', value);
  }

  /**
   * @type {date}
   */
  getStatusEndDate() {
    const value = this.getValueForAttribute('status_end_date');
    return value;
  }

  setStatusEndDate(value) {
    return this.setField('status_end_date', value);
  }

  /**
   * @type {date}
   */
  getStatusStartDate() {
    const value = this.getValueForAttribute('status_start_date');
    return value;
  }

  setStatusStartDate(value) {
    return this.setField('status_start_date', value);
  }

  /**
   * @type {string}
   */
  getTitle() {
    const value = this.getValueForAttribute('title');
    return value;
  }

  setTitle(value) {
    return this.setField('title', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUri() {
    const value = this.getValueForAttribute('uri');
    return value;
  }

  setUri(value) {
    return this.setField('uri', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getContentDiscoveryQuestionCount() {
        return this.getMeta().getContentDiscoveryQuestionCount();
      }
    
      isIncludedInPersonalizationSettings() {
        return this.getMeta().isIncludedInPersonalizationSettings();
      }
    
      getMatchingStandardsSearchCount() {
        return this.getMeta().getMatchingStandardsSearchCount();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getQuestionCount() {
        return this.getMeta().getQuestionCount();
      }
    
      getSearchRelevance() {
        return this.getMeta().getSearchRelevance();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getContentDiscoveryQuestionCount() {
            const value = meta.get('content_discovery_question_count');
            validate(value, 'content_discovery_question_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isIncludedInPersonalizationSettings() {
            const value = meta.get('included_in_personalization_settings');
            validate(value, 'included_in_personalization_settings');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getMatchingStandardsSearchCount() {
            const value = meta.get('matching_standards_search_count');
            validate(value, 'matching_standards_search_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getQuestionCount() {
            const value = meta.get('question_count');
            validate(value, 'question_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {float}
           */
          getSearchRelevance() {
            const value = meta.get('search_relevance');
            validate(value, 'search_relevance');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {standard_set_creator_v1 (one)}
   */
  getStandardSetCreator() {
    const value = this.getValueForAttribute('standard_set_creator');
    return value;
  }

  // -- Relationship Fields: standard_set_creator --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {standard_v1 (many)}
   */
  getStandards() {
    const value = this.getValueForAttribute('standards');
    return value;
  }

  // -- Relationship Fields: standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_subject_v1 (many)}
   */
  getAuthoringSubjects() {
    const value = this.getValueForAttribute('authoring_subjects');
    return value;
  }

  // -- Relationship Fields: authoring_subjects --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {curriculum_area_v1 (many)}
   */
  getCurriculumAreas() {
    const value = this.getValueForAttribute('curriculum_areas');
    return value;
  }

  // -- Relationship Fields: curriculum_areas --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_v2 (many)}
   */
  getSubjects() {
    const value = this.getValueForAttribute('subjects');
    return value;
  }

  // -- Relationship Fields: subjects --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyStandardSetModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'adoption_status': null,
  'case_id': null,
  'description': null,
  'id': null,
  'inserted_at': null,
  'meta.content_discovery_question_count': null,
  'meta.included_in_personalization_settings': null,
  'meta.matching_standards_search_count': null,
  'meta.question_count': null,
  'meta.search_relevance': null,
  'official_source_url': null,
  'status_end_date': null,
  'status_start_date': null,
  'title': null,
  'updated_at': null,
  'uri': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'adoption_status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'authoring_subjects_v1': new Set(['associated']),
  'case_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'curriculum_areas_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.content_discovery_question_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.included_in_personalization_settings': new Set(['exact','not','null']),
  'meta.matching_standards_search_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.question_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.search_relevance': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'official_source_url': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'standard_set_creator_v1': new Set(['associated']),
  'standards_v1': new Set(['associated']),
  'status_end_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'status_start_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'subjects_v1': new Set(['associated']),
  'subjects_v2': new Set(['associated']),
  'title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'uri': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'content_discovery_filtering': 'meta.context.content_discovery_filtering',
    'content_discovery_filters_guide_levels': 'meta.context.content_discovery_filters.guide_levels',
    'content_discovery_filters_hide_assessment_questions': 'meta.context.content_discovery_filters.hide_assessment_questions',
    'content_discovery_filters_hide_previously_assigned': 'meta.context.content_discovery_filters.hide_previously_assigned',
    'content_discovery_filters_negate_standards_filter': 'meta.context.content_discovery_filters.negate_standards_filter',
    'content_discovery_filters_question_difficulties': 'meta.context.content_discovery_filters.question_difficulties',
    'content_discovery_filters_question_types': 'meta.context.content_discovery_filters.question_types',
    'content_discovery_filters_standards': 'meta.context.content_discovery_filters.standards',
    'content_discovery_filters_subjects': 'meta.context.content_discovery_filters.subjects',
    'matching_standards_search_fields': 'meta.context.matching_standards.search_fields',
    'matching_standards_search_term': 'meta.context.matching_standards.search_term',
    'question_count': 'meta.context.question_count',
    'search_fields': 'meta.context.search.fields',
    'search_term': 'meta.context.search.term',
    'user_id': 'meta.context.user.id',
    'personalization_settings': 'meta.context.user.personalization_settings',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const StandardSet = StandardSetModelV1;
