/* eslint-disable */


// Programatically generated on Wed Apr 09 2025 19:08:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'test_editors_v1',
    'attributes': {
      'hired_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'last_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'first_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'read_only_test_posts_v1': {
        'type': 'one-to-many'
      },
      'test_avatar_v1': {
        'type': 'one-to-one'
      },
      'test_posts_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'hired_at': Empty,
    'test_avatar': Empty,
    'test_posts': Empty,
    'read_only_test_posts': Empty,
    'last_name': Empty,
    'updated_at': Empty,
    'inserted_at': Empty,
    'first_name': Empty,
    'id': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'last_name': '',
    'first_name': '',
    'id': '',
    'hired_at': new Date(),
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'test_posts': new List(),
    'read_only_test_posts': new List(),
    'meta': new Map(),
    'test_avatar': new Map(),
    'relationshipMeta': new Map()
  }
);

export class TestEditorModelV1 extends GenericModel(ModelRecord, ModelConfig, 'TestEditorModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyTestEditorModelV1Model = _emptyTestEditorModelV1Model ? _emptyTestEditorModelV1Model : new TestEditorModelV1(DefaultModel);
    return _emptyTestEditorModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'TestEditorModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'test_editor_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getFirstName() {
    const value = this.getValueForAttribute('first_name');
    return value;
  }

  setFirstName(value) {
    return this.setField('first_name', value);
  }

  /**
   * @type {datetime}
   */
  getHiredAt() {
    const value = this.getValueForAttribute('hired_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setHiredAt(value) {
    const momentValue = moment(value);
    return this.setField('hired_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLastName() {
    const value = this.getValueForAttribute('last_name');
    return value;
  }

  setLastName(value) {
    return this.setField('last_name', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {read_only_test_post_v1 (many)}
   */
  getReadOnlyTestPosts() {
    const value = this.getValueForAttribute('read_only_test_posts');
    return value;
  }

  // -- Relationship Fields: read_only_test_posts --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withReadOnlyTestPostRelationshipGetters(data) {
    return {
      getMessage() {
        return data.get('message', null);
      },
      getPrimaryEditor() {
        return data.get('primary_editor', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getReadOnlyTestPostRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'read_only_test_post'], new Map());
    if (!id) {
      return data.map((rel) => this.__withReadOnlyTestPostRelationshipGetters(rel));
    } else {
      return this.__withReadOnlyTestPostRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {test_avatar_v1 (one)}
   */
  getTestAvatar() {
    const value = this.getValueForAttribute('test_avatar');
    return value;
  }

  // -- Relationship Fields: test_avatar --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {test_post_v1 (many)}
   */
  getTestPosts() {
    const value = this.getValueForAttribute('test_posts');
    return value;
  }

  // -- Relationship Fields: test_posts --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withTestPostRelationshipGetters(data) {
    return {
      getMessage() {
        return data.get('message', null);
      },
      getPrimaryEditor() {
        return data.get('primary_editor', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getTestPostRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'test_post'], new Map());
    if (!id) {
      return data.map((rel) => this.__withTestPostRelationshipGetters(rel));
    } else {
      return this.__withTestPostRelationshipGetters(data.get(id, new Map()));
    }
  }

}

// -- Empty Model Instance --
let _emptyTestEditorModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'first_name': null,
  'hired_at': null,
  'id': null,
  'inserted_at': null,
  'last_name': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'first_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'hired_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'last_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'read_only_test_posts_v1': new Set(['associated']),
  'test_avatar_v1': new Set(['associated']),
  'test_posts_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const TestEditor = TestEditorModelV1;
