/* eslint-disable */


// Programatically generated on Wed Apr 09 2025 19:08:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'supplements_v1',
    'attributes': {
      'type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'content': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'owner_v1': {
        'type': 'one-to-one'
      },
      'owner_v2': {
        'type': 'one-to-one'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_subject': Empty,
    'authoring_questions': Empty,
    'name': Empty,
    'subject': Empty,
    'questions': Empty,
    'owner': Empty,
    'search_questions': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'content': Empty,
    'inserted_at': Empty,
    'type': Empty,
    'id': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'name': '',
    'type': '',
    'id': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_questions': new List(),
    'questions': new List(),
    'search_questions': new List(),
    'public_questions': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'subject': new Map(),
    'owner': new Map(),
    'content': new Map(),
    'relationshipMeta': new Map()
  }
);

export class SupplementModelV1 extends GenericModel(ModelRecord, ModelConfig, 'SupplementModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptySupplementModelV1Model = _emptySupplementModelV1Model ? _emptySupplementModelV1Model : new SupplementModelV1(DefaultModel);
    return _emptySupplementModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'SupplementModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'supplement_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {map}
   */
  getContent() {
    const value = this.getValueForAttribute('content');
    return value;
  }

  setContent(value) {
    return this.setField('content', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {string}
   */
  getType() {
    const value = this.getValueForAttribute('type');
    return value;
  }

  setType(value) {
    return this.setField('type', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {user_v2 (one)}
   */
  getOwner() {
    const value = this.getValueForAttribute('owner');
    return value;
  }

  // -- Relationship Fields: owner --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withPublicQuestionRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getPublicQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'public_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withPublicQuestionRelationshipGetters(rel));
    } else {
      return this.__withPublicQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionRelationshipGetters(rel));
    } else {
      return this.__withQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSearchQuestionRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSearchQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'search_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSearchQuestionRelationshipGetters(rel));
    } else {
      return this.__withSearchQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptySupplementModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'id': null,
  'inserted_at': null,
  'name': null,
  'type': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'content': new Set(['null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'owner_v1': new Set(['associated']),
  'owner_v2': new Set(['associated']),
  'public_questions_v1': new Set(['associated']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Supplement = SupplementModelV1;
